import * as React from 'react'
import { connect } from 'react-redux'
import { Drawer, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { BaseTable } from 'component/Tables'
import { CLIENTS_COLUMNS } from 'component/Tables/DocketsTable/columns'
import { Dropdown, SearchInput } from 'component/Inputs'
import { useDebounce } from 'utils'
import { CLIENT_LEGAL_FORM_DISPLAY_FILTERS } from '../../typescript/clients.constants'
import MuiTablePagination from 'component/Tables/Pagination/MuiTablePagination'
import ClientsPanel from '../ClientPanel'
import { getClients, getContractsInsurances } from '../../core/Clients.services'
import { IAPICommissionClient } from 'api/interfaces/entities'
import { IDropDownOption } from 'component/Inputs/Dropdown'
import EventEmitter, { UPDATE_EVENT_CONTRACTS } from 'utils/events'
import { getProviderLogo } from 'utils/commissions'

interface IProps {
  user: any
}

const ClientsList: React.FC<IProps> = (props) => {
  const { user } = props
  const [loading, setLoading] = React.useState(false)
  const [searchTerms, setSearchTerms] = React.useState<string>('')
  const [insurancesFilters, setInsurancesFilters] = React.useState<IDropDownOption[]>([
    {
      display: 'Tous les assureurs',
    },
  ])
  const [selectedFilter, setSelectedFilter] = React.useState<{
    clientLegalForm?: string
    insuranceId?: string
  }>({})
  const debounceSearchTerms = useDebounce(searchTerms, 800)
  const [clients, setClients] = React.useState<IAPICommissionClient[]>([])
  const [page, setPage] = React.useState(0)
  const [perPage, setPerPage] = React.useState(25)
  const [total, setTotal] = React.useState(0)
  const [openClient, setOpenClient] = React.useState<IAPICommissionClient | null>(null)
  const [filterParams, setFilterParams] = React.useState({
    page,
    count: perPage,
    search: debounceSearchTerms,
    clientLegalForm: selectedFilter.clientLegalForm,
    insuranceId: selectedFilter.insuranceId,
  })

  React.useEffect(() => {
    if (
      debounceSearchTerms !== filterParams.search ||
      selectedFilter.clientLegalForm !== filterParams.clientLegalForm ||
      selectedFilter.insuranceId !== filterParams.insuranceId ||
      page !== 0 ||
      perPage !== filterParams.count
    ) {
      setPage(0)
      setFilterParams({
        page: 0,
        count: perPage,
        search: debounceSearchTerms,
        clientLegalForm: selectedFilter.clientLegalForm,
        insuranceId: selectedFilter.insuranceId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearchTerms, selectedFilter])

  React.useEffect(() => {
    if (page !== filterParams.page || perPage !== filterParams.count) {
      setFilterParams({
        page,
        count: perPage,
        search: debounceSearchTerms,
        clientLegalForm: selectedFilter.clientLegalForm,
        insuranceId: selectedFilter.insuranceId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage])

  const fetchClients = React.useCallback(async () => {
    const res = await getClients({
      filterParams,
      setLoading,
    })

    if (!res) {
      return
    }

    setTotal(res.clientCount)
    setClients(res.clients)
  }, [filterParams])

  const fetchInsurancesFilters = async () => {
    const res = await getContractsInsurances(false)

    if (!res) {
      return
    }

    setInsurancesFilters([
      {
        display: 'Tous les assureurs',
      },
      ...res.insurances.map((insurance) => ({
        value: insurance.id.toString(),
        display: insurance.provider,
        logo: getProviderLogo(insurance.provider),
      })),
    ])
  }

  React.useEffect(() => {
    EventEmitter.subscribe(UPDATE_EVENT_CONTRACTS, () => {
      fetchClients()
      fetchInsurancesFilters()
    })
    fetchInsurancesFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchClients])

  React.useEffect(() => {
    fetchClients()
  }, [filterParams, fetchClients])

  const updateSearch = (value: string) => {
    setSearchTerms(value)
  }

  // @ts-ignore
  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: React.BaseSyntheticEvent) => {
    setPage(0)
    setPerPage(event.target.value)
  }

  const updateClientsFilter = (event: SelectChangeEvent<unknown>) => {
    setSelectedFilter((prevState) => ({
      ...prevState,
      clientLegalForm: event.target.value as string,
    }))
  }

  const updateInsurersFilter = (event: SelectChangeEvent<unknown>) => {
    setSelectedFilter((prevState) => ({
      ...prevState,
      insuranceId: event.target.value as string,
    }))
  }

  if (!user.organization.enableReports) {
    window.location.href = '/'
    return <></>
  }

  return (
    <div className="container commissions--container">
      <Stack direction="row" alignItems="center" mb={2}>
        <SearchInput
          id="search"
          value={searchTerms}
          onChange={(e) => updateSearch(e.target.value)}
          onReset={() => updateSearch('')}
          size="small"
          sx={{ mr: 1 }}
        />
        <Dropdown
          id="clients-filter"
          value={
            CLIENT_LEGAL_FORM_DISPLAY_FILTERS.find(
              (filter) => filter.value === selectedFilter.clientLegalForm
            )?.value
          }
          onChange={updateClientsFilter}
          placeholder="Filtrer par"
          options={CLIENT_LEGAL_FORM_DISPLAY_FILTERS}
          size="small"
          sx={{ mr: 1 }}
        />
        <Dropdown
          id="insurers-filter"
          value={
            insurancesFilters.find((filter) => filter.value === selectedFilter.insuranceId)?.value
          }
          onChange={updateInsurersFilter}
          placeholder="Filtrer par"
          options={insurancesFilters}
          size="small"
          sx={{ mr: 1 }}
        />
        <MuiTablePagination
          count={total}
          rowsPerPage={perPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Clients par page"
          sx={{ width: 'auto', marginLeft: 'auto' }}
        />
      </Stack>
      <BaseTable
        autoHeight
        rowHeight={56}
        columnHeaderHeight={56}
        columns={CLIENTS_COLUMNS}
        rows={clients}
        noContentMessage="Aucun client à afficher."
        defaultGroupingExpansionDepth={-1}
        pagination={false}
        slots={{
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {searchTerms ? (
                <Typography variant="subtitle1" component="p" color="text.secondary" px={1}>
                  Aucun client ne correspond à votre recherche.
                </Typography>
              ) : (
                <>
                  <Typography variant="subtitle1" component="p" color="text.secondary" px={1}>
                    Aucun client à afficher.
                  </Typography>
                </>
              )}
            </Stack>
          ),
        }}
        onRowClick={(data) => {
          setOpenClient(data.row)
        }}
        loading={loading}
        sx={{ overflow: 'hidden' }}
      />
      <Drawer
        open={!!openClient}
        onClose={() => setOpenClient(null)}
        anchor="right"
        PaperProps={{ sx: { width: '80vw' } }}
      >
        <ClientsPanel client={openClient} onClose={() => setOpenClient(null)} />
      </Drawer>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(ClientsList)
