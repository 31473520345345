import { Box, Button, Grid, Stack, Typography, styled } from '@mui/material'
import { API_DOCKETS_URL } from 'api/constants'
import { customFetch } from 'api/customFetch'
import { IAPIInsurance, IAPIUploadedReport, IAPIUploadedReportCommission } from 'api/interfaces'
import Modal from 'component/Modal'
import InsuranceSelector from 'component/InsuranceSelector'
import React, {
  useState,
  FormEventHandler,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react'
import { useModalUploadMultipleDockets } from './core/ModalUploadMultipleDockets.hook'
import moment, { Moment } from 'moment'
import { unaccent } from 'utils'
import { ArrowLeftIcon, ArrowRightIcon, InfoIcon } from 'icons'
import { ImportLine } from './components/ImportLine'
import { FilesList } from './components/FilesList'
import { FilesInput } from './components/FilesInput'
import { DateInput } from 'component/Inputs'
import { ModalContext } from 'utils/hooks/modalBehavior'
import { AbeilleImportLine } from './components/AbeilleImportLine'

window.Buffer = window.Buffer || require('buffer').Buffer

const ModalUploadMultipleDockets = () => {
  const modal = useContext(ModalContext)
  const totalSteps = 3
  const [step, setStep] = useState<number>(0)

  const [error, setError] = useState<string | undefined>()
  const [jobId, setJobId] = useState<string>()
  const [files, setFiles] = useState<File[]>([])
  const [insurances, setInsurances] = useState<IAPIInsurance[] | undefined>()
  const [selectedInsurance, setSelectedInsurance] = useState<IAPIInsurance | undefined>()
  const [currentFetchingReportIndex, setcurrentFetchingReportIndex] = useState<number | null>(null)
  const [isAbeilleOutstandingContract, setIsAbeilleOutstandingContract] = useState<boolean>(false)
  const [period, setPeriod] = useState<Moment | null>(null)

  const [uploadedReports, setUploadedReports] = useState<IAPIUploadedReport[]>([])

  useEffect(() => {
    if (!modal.currentModal || modal.currentModal.name !== 'ModalUploadMultipleDockets') {
      setcurrentFetchingReportIndex(null)
      setStep(0)
      setUploadedReports([])
      setJobId('')
      setError(undefined)
      setPeriod(null)
      setIsAbeilleOutstandingContract(false)
      setSelectedInsurance(undefined)
      setFiles([])
    } else if (
      modal.currentModal &&
      modal.currentModal.name === 'ModalUploadMultipleDockets' &&
      !selectedInsurance
    ) {
      setSelectedInsurance(modal.currentModal.initialSelectedInsurance)
    }
  }, [modal.currentModal, selectedInsurance])

  const isAbeilleInsurance = useMemo(() => {
    return selectedInsurance?.provider === 'abeille'
  }, [selectedInsurance])

  const handleNextUpload = useCallback(
    (error?: string) => {
      if (currentFetchingReportIndex === null) return

      if (isAbeilleInsurance && error) {
        setStep(1)
        setUploadedReports([])
        setcurrentFetchingReportIndex(null)
        setError(error)
      } else {
        setcurrentFetchingReportIndex(currentFetchingReportIndex + 1)
      }
    },
    [isAbeilleInsurance, currentFetchingReportIndex]
  )

  const updateUploadedReports = ({
    id,
    progress,
    error,
    fileName,
    loading,
    commissions,
  }: {
    id: number
    progress?: number
    error?: string
    fileName?: string
    loading?: boolean
    commissions?: IAPIUploadedReportCommission[]
  }) => {
    setUploadedReports((prevState) => {
      const prevStateCopy = [...prevState]
      const currentReport = prevStateCopy.find((report) => report.id === id)
      const currentReportIndex = prevStateCopy.findIndex((report) => report.id === id)

      if (currentReport) {
        prevStateCopy.splice(currentReportIndex, 1, {
          ...currentReport,
          fileName: fileName || currentReport.fileName,
          error: error || currentReport.error,
          progress: progress || currentReport.progress,
          loading: loading !== undefined ? loading : currentReport.loading,
          commissions: commissions || currentReport.commissions,
        })
      }

      return prevStateCopy
    })
  }

  useModalUploadMultipleDockets({
    jobId,
    setJobId,
    setInsurances,
    setSelectedInsurance,
    updateUploadedReports,
    currentFetchingReportIndex,
    uploadedReports,
    handleNextUpload,
    modal: modal.currentModal,
  })

  const fetchReport = useCallback(async () => {
    if (currentFetchingReportIndex === null) return
    const file = files[currentFetchingReportIndex]

    if (!selectedInsurance || !file || currentFetchingReportIndex === files.length) {
      return
    }

    let isAbeilleOutstandingContract = false

    let fileName = file.name
    let fileArrayBuffer = await file.arrayBuffer()
    let fileHeader = await file.slice(0, 2).text()

    let buffer = Buffer.from(fileArrayBuffer)
    let fileData =
      (selectedInsurance.provider === 'axa' ||
        selectedInsurance.provider === 'cardif' ||
        selectedInsurance.provider === 'abeille' ||
        selectedInsurance.provider === 'generali' ||
        selectedInsurance.provider === 'intencial' ||
        selectedInsurance.provider === 'ag2r') &&
      fileName.toLowerCase().indexOf('.csv') > 0
        ? buffer.toString('latin1')
        : file

    // Upload fix for ODDO Excel files exported with .csv extension
    if (fileName.toLowerCase().endsWith('.csv') && fileHeader === 'PK') {
      fileName = fileName.toLowerCase().replaceAll('.csv', '.xlsx')
      fileData = new File(
        [new Uint8Array(fileArrayBuffer, 0, fileArrayBuffer.byteLength)],
        fileName,
        {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheets',
        }
      )
    }

    updateUploadedReports({
      id: currentFetchingReportIndex,
      fileName,
    })

    // Check if file is an Abeille outstanding contract
    if (isAbeilleInsurance) {
      let firstLineData = fileData.toString().split('\n')[0].split(';')
      // remove all empty fields at the end of the line
      while (firstLineData[firstLineData.length - 1].trim() === '') firstLineData.pop()

      const firstField = unaccent(firstLineData[0]).toLowerCase()
      if (firstField === 'bordereau encours epargne' || firstField === 'bordereau encours afer') {
        isAbeilleOutstandingContract = true
        setIsAbeilleOutstandingContract(true)
      }

      if (!period && isAbeilleOutstandingContract) {
        setcurrentFetchingReportIndex(null)
        setUploadedReports([])
        return
      } else if ((period && isAbeilleOutstandingContract) || !isAbeilleOutstandingContract) {
        setStep(2)
      }
    }

    const form = new FormData()
    form.append('file', fileData)
    form.append('fileName', fileName)
    form.append('insuranceId', selectedInsurance.id.toString())

    // Add period if defined - Abeille outstanding contracts only
    period && form.append('period', moment(period).format('YYYY-MM'))

    updateUploadedReports({
      id: currentFetchingReportIndex,
      loading: true,
    })

    const res = await fetch(API_DOCKETS_URL, {
      credentials: 'include',
      headers: {
        'x-csrf-token': customFetch.getCSRFToken(),
      },
      method: 'POST',
      body: form,
    }).catch((e) => {
      const errorMessage = `L'envoi du fichier a échoué. Si le problème persiste, contactez le support Sendraise.`
      updateUploadedReports({
        id: currentFetchingReportIndex,
        error: errorMessage,
        loading: false,
      })
      handleNextUpload(errorMessage)
    })

    if (!res) {
      const errorMessage = `Une erreur est survenue durant le traitement de votre fichier. Si le problème persiste, contactez le support Sendraise.`

      updateUploadedReports({
        id: currentFetchingReportIndex,
        error: errorMessage,
        loading: false,
      })
      handleNextUpload(errorMessage)
      return
    }

    const jsonRes = await res.json()

    if (!res.ok) {
      if (jsonRes.message) {
        updateUploadedReports({
          id: currentFetchingReportIndex,
          error: jsonRes.message,
          loading: false,
        })
        handleNextUpload(jsonRes.message)

        return
      }

      const errorMessage = `Une erreur est survenue durant le traitement de votre fichier. Si le problème persiste, contactez le support Sendraise.`

      updateUploadedReports({
        id: currentFetchingReportIndex,
        error: errorMessage,
        loading: false,
      })
      handleNextUpload(errorMessage)

      return
    }

    setJobId(jsonRes.jobId)
  }, [
    isAbeilleInsurance,
    selectedInsurance,
    files,
    handleNextUpload,
    period,
    currentFetchingReportIndex,
  ])

  useEffect(() => {
    if (currentFetchingReportIndex !== null) {
      fetchReport()
    }
  }, [currentFetchingReportIndex, fetchReport, files])

  const handleSubmit: FormEventHandler = async (e) => {
    setError(undefined)
    e.preventDefault()

    if (!insurances || !files.length || !selectedInsurance) {
      setError('Remplissez tous les champs')
      return
    }

    if (!isAbeilleInsurance) {
      setStep(step + 1)
    }
    setUploadedReports(
      files.map((file, index) => ({
        id: index,
        insurance: {
          id: selectedInsurance.id,
          name: selectedInsurance.name,
          provider: selectedInsurance.provider,
          insuranceMessageInfos: selectedInsurance.insuranceMessageInfos,
          reportCount: selectedInsurance.reportCount || 0,
        },
        fileName: file.name,
        commissions: [],
        error: undefined,
        progress: 0,
        loading: false,
      }))
    )

    setcurrentFetchingReportIndex(0)
  }

  const handleNext = () => {
    setStep(step + 1)
  }

  const handlePrevious = () => {
    setIsAbeilleOutstandingContract(false)
    setStep(step - 1)
  }

  const progress = useMemo(() => {
    if (
      currentFetchingReportIndex !== null &&
      currentFetchingReportIndex >= uploadedReports.length
    ) {
      return 101
    } else if (
      currentFetchingReportIndex !== null &&
      currentFetchingReportIndex < uploadedReports.length
    ) {
      const currentFileProgress =
        uploadedReports[currentFetchingReportIndex].progress / uploadedReports.length
      const fileProgress = (currentFetchingReportIndex / uploadedReports.length) * 100
      return Math.round(fileProgress + currentFileProgress)
    } else {
      return 0
    }
  }, [uploadedReports, currentFetchingReportIndex])

  const selectInsuranceStep = () =>
    insurances && (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1.5}>
          {insurances?.map((insurance) => (
            <Grid item key={insurance.id} xs={2}>
              <InsuranceSelector
                name={insurance.name}
                provider={insurance.provider}
                onClick={() => setSelectedInsurance(insurance)}
                selected={selectedInsurance ? insurance.id === selectedInsurance.id : false}
              />
            </Grid>
          ))}
        </Grid>
      </form>
    )

  const selectDocumentStep = () => (
    <form>
      <FilesInput
        files={files}
        setFiles={setFiles}
        insurance={selectedInsurance?.provider || ''}
        isAbeilleOutstandingContract={isAbeilleOutstandingContract}
        period={period}
        setIsAbeilleOutstandingContract={setIsAbeilleOutstandingContract}
        error={error}
        setError={setError}
      />
      {files.length > 0 && !isAbeilleInsurance && (
        <FilesList
          files={files}
          setFiles={setFiles}
          setIsAbeilleOutstandingContract={setIsAbeilleOutstandingContract}
        />
      )}

      {isAbeilleOutstandingContract && (
        <DateInput
          id="period"
          label="Période couverte par le bordereau"
          value={period}
          onChange={setPeriod}
          placeholder="Février 2022"
          disableFuture
          sx={{ mt: 2, width: '100%' }}
          views={['month', 'year']}
        />
      )}
    </form>
  )

  const resultStep = () => (
    <>
      {progress < 100 && (
        <Stack
          direction="row"
          gap={1}
          bgcolor="#DFECFB"
          color="primaryLight.contrastText"
          p={1.5}
          mb={2}
          borderRadius={1}
        >
          <InfoIcon />
          <Typography variant="subtitle1" color="primaryLight.contrastText">
            <b>Poursuivez votre activité pendant l’import.</b>
            <br />
            Cliquez sur “Réduire le détail” en haut à droite de cette fenêtre pour poursuivre votre
            activité pendant l’import. Conservez Sendraise ouvert pour ne pas l’interrompre.
          </Typography>
        </Stack>
      )}
      {uploadedReports.map((uploadedReport) => {
        return isAbeilleInsurance ? (
          <AbeilleImportLine uploadedReport={uploadedReport} key={uploadedReport.id} />
        ) : (
          <ImportLine uploadedReport={uploadedReport} key={uploadedReport.id} />
        )
      })}
    </>
  )

  const renderStep = () => {
    switch (step) {
      case 0:
        return selectInsuranceStep()
      case 1:
        return selectDocumentStep()
      case 2:
        return resultStep()
      default:
        console.log('unknown step')
    }
  }

  const modalStepTitle = React.useMemo(() => {
    switch (step) {
      case 0:
        return 'Sélectionner le fournisseur des bordereaux'
      case 1:
        return isAbeilleInsurance ? 'Importer le document' : 'Sélectionner les bordereaux à ajouter'
      case 2:
        return isAbeilleInsurance
          ? 'Vérification'
          : progress >= 100
          ? 'Analyse terminée'
          : `Analyse des bordereaux (${progress}%)`
      default:
        return ''
    }
  }, [step, progress, isAbeilleInsurance])

  useEffect(() => {
    if (progress >= 100 && modal.currentModal) {
      modal.currentModal.success = true
    }
  }, [progress, modal.currentModal])

  return (
    <Modal
      open={!!(modal.currentModal && modal.currentModal.name === 'ModalUploadMultipleDockets')}
      onClose={() => modal.setCurrentModal(null)}
      stepTitle={`Importer des bordereaux - ${step + 1}/${totalSteps}`}
      title={modalStepTitle}
      expandable={step === 2}
      collapsedTitle={
        progress >= 100
          ? `${
              uploadedReports.length > 1
                ? `${uploadedReports.length} bordereaux importés`
                : `${uploadedReports.length} bordereau importé`
            }`
          : 'Import de bordereaux en cours'
      }
      collapsedStepTitle={
        progress >= 100
          ? 'Analyse terminée'
          : `${currentFetchingReportIndex !== null ? currentFetchingReportIndex : 0} ${
              currentFetchingReportIndex && currentFetchingReportIndex > 1
                ? 'bordereaux traités'
                : 'bordereau traité'
            } sur ${uploadedReports.length}`
      }
      success={progress >= 100}
      modalId="ModalUploadMultipleDockets"
      sx={{ '.MuiDialog-paper': { maxWidth: '800px', overflowX: 'hidden' } }}
      helpUrl={
        step === 0 || step === 1
          ? 'https://busy-stop-fcc.notion.site/2e20ec84baf44396aa0688adeb265107?v=b4a445010e8e441b89559a0f5f0aa6a2&pvs=4'
          : ''
      }
      helpText={
        step === 0 || step === 1 ? 'Besoin d’aide pour récupérer ou choisir vos bordereaux ?' : ''
      }
    >
      {step === 2 && (
        <CustomProgressBar className="progress-bar" sx={{ opacity: progress >= 100 ? 0 : 1 }}>
          <Box sx={{ width: `${progress}%` }}></Box>
        </CustomProgressBar>
      )}
      <CustomModalContent className={step === 2 ? 'scrollable' : ''}>
        {renderStep()}
      </CustomModalContent>
      {step < 2 && (
        <Modal.Actions>
          <Stack flexGrow={1} flexDirection="row" justifyContent="space-between">
            {step > 0 && (
              <Button
                variant="text"
                color="neutral"
                onClick={handlePrevious}
                startIcon={<ArrowLeftIcon />}
              >
                Précédent
              </Button>
            )}
            <Button
              disabled={step === 0 ? !selectedInsurance?.provider : !files.length}
              endIcon={<ArrowRightIcon />}
              onClick={step === 0 ? handleNext : handleSubmit}
              sx={{ ml: 'auto' }}
            >
              Continuer
            </Button>
          </Stack>
        </Modal.Actions>
      )}
    </Modal>
  )
}

export default ModalUploadMultipleDockets

const CustomModalContent = styled(Modal.Content)(({ theme }) => ({
  '&.scrollable': {
    paddingTop: `${theme.spacing(2)} !important`,
    paddingBottom: `${theme.spacing(2)} !important`,
    maxHeight: 'min(740px, 80dvh)',
    overflowY: 'auto',
  },
}))

const CustomProgressBar = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  height: 4,
  background: 'rgba(255, 255, 255, 0.10)',

  '.MuiBox-root': {
    height: '100%',
    background: 'white',
    transition: 'width 1s linear',
  },
}))
